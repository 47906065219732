/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */
import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BlackBtn } from "views/Admin/Shared/Forms/BlackBtn";
import ViewPhoto from "views/Campaign/CenteredModal/ViewPhoto";
import ServicePhoto from "./ServicePhoto";
import API from "services/axios-config";
import { enqueueSnackbar } from "notistack";
import { useRightModal } from "views/Lists/Campaigns/rightModalContext";
import "./style.scss";
import { getClientVisibleObject } from "utils/helpers.js";
const ComponentGaleriePhoto = (props) => {
  const { campaignId, missionId } = props;
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [clientVisible, setClientVisible] = useState(null);

  const [select, setSelect] = useState(false);
  const [openPublicateModal, setOpenPublicateModal] = useState(false);
  const { removedLastStack } = useRightModal();

  const handleSelection = () => {
    setSelect((prev) => !prev);
    if (select) {
      setSelectedImages([]);
    }
  };

  const handlePublicatePhoto = () => {
    if (selectedImages.length > 0) setOpenPublicateModal(true);
  };

  useEffect(() => {
    setClientVisible(getClientVisibleObject(selectedImages));
  }, [selectedImages]);
  useEffect(() => {
    if (campaignId) {
      API.get(`/campaigns/${campaignId}/campaign_photos`)
        .then((res) => {
          setImages(res.data);
        })
        .catch((err) => {
          enqueueSnackbar(
            "Erreur lors de la requete pour chercher les photos liéés à cette campagne : ",
            err,
            { variant: "error" }
          );
          removedLastStack();
        });
    } else if (missionId) {
      API.get(`/missions/${missionId}/mission_photos`)
        .then((res) => {
          setImages(res.data);
        })
        .catch((err) => {
          enqueueSnackbar(
            "Erreur lors de la requete pour chercher les photos liéés à cette campagne : ",
            err,
            {
              variant: "error",
            }
          );
          removedLastStack();
        });
    }
  }, []);

  const updateImagesClientVisiblityByIds = React.useCallback((imagesIdsToUpdate, clientVisible) => {
    for (const store in images) {
      for (const date in images[store]) {
        images[store][date] = images[store][date].map(image => {
          if (imagesIdsToUpdate.includes(image.id)) {
            return {
              ...image,
              client_visible: clientVisible
            };
          }
          return image;
        });
      }
    }
    setSelectedImages([]);
    setOpenPublicateModal(false);
    setSelect(false);
  }, [images])

  const handleDownloadPhoto = () => {
    console.log(selectedImages);
    if (selectedImages.length > 0) {
      const imagesIds = selectedImages.map((image) => image.id);
      const downloadUrl = `${process.env.REACT_APP_BACK_API
        }/download_images_by_id?images_ids=${imagesIds.join(',')}`;
      const downloadWindow = window.open(downloadUrl, "_blank");
      setTimeout(() => {
        downloadWindow.close();
      }, 300000);
    }
  };

  const handleDownloadAllPhoto = () => {
    let downloadUrl;
    let downloadWindow;
    if (campaignId) {
      downloadUrl = `${process.env.REACT_APP_BACK_API}/download_images?campaign_id=${campaignId}`;
      downloadWindow = window.open(downloadUrl, "_blank");
    } else if (missionId) {

      const imagesIds = Object.keys(images).flatMap(key => {
        return Object.values(images[key]).flatMap(mission => {
          return mission.map(item => item.id);
        });
      });
      downloadUrl = `${process.env.REACT_APP_BACK_API}/download_images_by_id?images_ids=${imagesIds.join(',')}`;
      downloadWindow = window.open(downloadUrl, "_blank");
    }
    setTimeout(() => {
      downloadWindow?.close();
    }, 300000);
  };

  const addAdminPhotos = (newImages, prestationType) => {
    setImages((prev) => {
      const existingPrestationTypeData = prev?.[prestationType] ?? {};
      const existingPrestationTypeAdminPhotos = existingPrestationTypeData["Photos Admin"] ?? [];

      return {
        ...prev,
        [prestationType]: {
          ...existingPrestationTypeData,
          "Photos Admin": [...existingPrestationTypeAdminPhotos, ...newImages],
        },
      };
    });
  }

  return (
    <div className="component-photo-details">
      <div className="photo-details-header">
        <div>Galerie Photo</div>
        <BlackBtn
          label={select ? "ANNULER SÉLECTION" : "SÉLÉCTIONNER"}
          styles={{ color: select ? "red" : "#60eed2" }}
          handleAction={() => handleSelection()}
        />
      </div>
      {Object.entries(images).map((prestationImages, index) => (
        <ServicePhoto
          key={index}
          {...props}
          {...{
            prestationImages,
            select,
            selectedImages,
            setSelectedImages,
            images,
            addAdminPhotos,
          }}
        />
      ))}
      <div className="photo-details-actions">
        {select
          ? (
            <>
              <BlackBtn
                label="TÉLÉCHARGER LES PHOTOS"
                styles={{ color: "#60eed2" }}
                handleAction={() => handleDownloadPhoto()}
              />
              {/* Afficher un message d'avertissement si des photos publiées et non publiées sont sélectionnées */}
              {selectedImages.length > 0 && !clientVisible.client_visible && (
                <div className="message-public">
                  Tu as sélectionné des photos déjà publiées ainsi que des photos
                  non publiées. Tu dois modifier ta sélection pour publier ou
                  dépublier des photos.
                </div>
              )}
              <BlackBtn
                handleAction={() => handlePublicatePhoto()}
                styles={
                  !clientVisible?.client_visible
                    ? {
                      cursor: "not-allowed",
                      backgroundColor: "gray",
                      color: "white",
                    }
                    : clientVisible.client_visible.value
                      ? { color: "red" }
                      : { color: "white" }
                }
                label={
                  clientVisible && clientVisible.client_visible
                    ? clientVisible.client_visible.value
                      ? "DÉPUBLIER LES PHOTOS"
                      : "PUBLIER LES PHOTOS"
                    : "PUBLICATION"
                }
              />
            </>
          ) : (
            <BlackBtn
              label="TÉLÉCHARGER TOUTES LES PHOTOS"
              styles={{ color: "#60eed2" }}
              handleAction={() => handleDownloadAllPhoto()}
            />
          )}
      </div>
      <Modal
        open={!!openPublicateModal}
        onClose={() => setOpenPublicateModal(false)}
      >
        <ViewPhoto label={

          clientVisible?.client_visible.value
            ? "DÉPUBLIER"
            : "PUBLIER"

        }{...{ selectedImages, setOpenPublicateModal, updateImagesClientVisiblityByIds }} />
      </Modal>
    </div>
  );
};

export default ComponentGaleriePhoto;

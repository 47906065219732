/* eslint-disable */
import { Modal } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import API from 'services/axios-config'
import { ButtonComponent } from 'views/Admin/Shared/Buttons/Generic'
import { SelectObjectsComponent } from 'views/Admin/Shared/Forms/SelectObjects'

const AnomalieMissionModal = ({ open, close, anomalies, setPrestationsList, prestationsList }) => {
  const [missionList, setMissionList] = useState([])
  const [updatedAnomalies, setUpdatedAnomalies] = useState();
  const [searchParams] = useSearchParams()
  const campaignId = searchParams?.get('campaign_id');
  const [isAllAnomaliesAssigned, setIsAllAnomaliesAssigned] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const currentPresta = prestationsList.find(presta => presta.id == anomalies.prestaId)

  useEffect(() => {
    const notEmptySlots = currentPresta?.slots?.filter(slot =>
      slot?.items?.some(item => item.user)
    );
    setMissionList(notEmptySlots?.map(slot => ({ ...slot, sm_full_name: `${slot.title || 'NOUVEAU SLOT'} - ${slot?.items?.find(item => item.user).user?.first_name} ${slot?.items?.find(item => item.user)?.user?.last_name}` })))
  }, [prestationsList, anomalies])

  useEffect(() => {
    setUpdatedAnomalies(anomalies.newAnomalies)
  }, [anomalies])

  useEffect(() => {
    const allAssignedMissions = updatedAnomalies?.every(anomalie => anomalie.missionId);
    setIsAllAnomaliesAssigned(allAssignedMissions);
  }, [updatedAnomalies])

  function handleAssignMission(index, mission) {
    setUpdatedAnomalies(prevAnomalies =>
      prevAnomalies.map((anomalie, i) => {
        if (index === i) {
          return {
            ...anomalie,
            missionId: mission?.items?.[0]?.id
          };
        }
        return anomalie;
      })
    );
  }

  const handleConfirm = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await API.post(`/campaigns/${campaignId}/spots/upload_anomalies_csv`, { data: [...updatedAnomalies, ...anomalies.data], prestaId: anomalies.prestaId, campaignId: campaignId })
      const spotsResult = res.data.data || [];
      setPrestationsList((prevList) =>
        prevList.map((prestation) => {
          if (prestation.id === anomalies.prestaId) {
              return {
                  ...prestation,
                  anomalies: spotsResult
              };
          }
          return prestation;
        })
      );
      if (spotsResult.length > 0) {
        enqueueSnackbar(`${spotsResult.length} anomalies ont été importés avec succès.`, { variant: 'success', autoHideDuration: 5000 });
      }

    } catch (error) {
      enqueueSnackbar("Erreur lors de l'import des anomalies.", { variant: 'error' })
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }, [updatedAnomalies, campaignId])


  function handleClose() {
    close()
  }

  const renderMissionItem = (props, option) => (
    <li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>{option.sm_full_name}</div>
    </li>
  )

  return (
    <Modal open={open} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ padding: 20, backgroundColor: '#fff', borderRadius: 15, width: '50%', maxHeight: '80vh', overflow: 'hidden' }}>
        <div style={{ marginBottom: 15 }}>Assigner une mission pour chaque anomalie :</div>
        <div style={{ maxHeight: '60vh', overflowY: 'auto', paddingRight: 10, paddingTop: 20 }}>
          {anomalies.newAnomalies?.map((anomalie, index) => (
            <div key={index} style={{
              display: 'flex', flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 20
            }}>
              <div style={{ color: 'black', width: 'fit-content' }}>{anomalie.anomalieType} - {anomalie.address_attributes.label}</div>
              <div style={{ minWidth: '50%' }}><SelectObjectsComponent
                name={`Mission_${index}`}
                handleAction={(e) => handleAssignMission(index, e)}
                items={missionList}
                item_name="sm_full_name"
                label='Mission'
                customRenderOption={renderMissionItem}
              /></div>
            </div>
          ))}
         </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <ButtonComponent
            text={isLoading ? "Chargement..." : "Confirmer"}
            handleActions={handleConfirm}
            disabled={!isAllAnomaliesAssigned || isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
export default AnomalieMissionModal

import React, { useState, useEffect } from 'react'
import {
  Switch,
  styled,
  alpha,
  Rating,
  CircularProgress,
} from "@mui/material";
import API from 'services/axios-config';
import moment from 'moment';
import BtnComponent from 'components/GlobalInputs/Btn';
import { InputText } from 'components/GlobalInputs/InputText';
import { SelectInputMultiple } from 'components/GlobalInputs/Select';
import { ReactComponent as TrashSvg } from "assets/Download-test.svg";
import SpotsNoteSection from './spotsNoteSection';
import './styles.scss'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowUp } from "assets/arrow-up-right.svg";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#60EED2",
    '&:hover': {
      backgroundColor: alpha("#60EED2", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#60EED2",
  },
}));

const RightBlockValidation = ({ missionSelected, documentWaitingSend, handleRemoveDocument, handleAddDocument, setData, setMissionSelected, setModalOpen, newData, setNewData, handleSubmitData }) => {
  const [loading, setIsLoading] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    const timeWork = missionSelected?.real_time ? missionSelected?.real_time : (calculateDifferenceInMinutes(missionSelected.check_in, missionSelected.check_out) - missionSelected.pausedTime)
    setTotalAmount(parseFloat(minutesToAmount(timeWork, missionSelected?.slot?.cost)) + parseFloat(newData?.bonus_sm || 0))
  }, [missionSelected, newData])

  useEffect(() => {
    if (missionSelected && (!missionSelected.pausedTime && missionSelected.pausedTime !== 0)) {
      setIsLoading(true)
      API.get(`missions/${missionSelected?.id}/paused_time`)
        .then(res => {
          setData(prev => prev.map(item => item.id === missionSelected.id ? ({ ...missionSelected, pausedTime: res.data || 0 }) : item))
          setMissionSelected(prev => ({ ...prev, pausedTime: res.data || 0 }))
        })
        .finally(_ => setIsLoading(false))
    }
  }, [missionSelected])

  function getStatus(validated, treated) {
    if (!validated && !treated) return ("EN ATTENTE DE VÉRIFICATION")
    if (validated && !treated) return ("MISSION VALIDÉE")
    if (treated) return ("PAYÉ")
  }

  function formatMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} Heures ${remainingMinutes.toFixed(0)} minutes`;
  }

  function calculateDifferenceInMinutes(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'minutes');
  }

  function handleOpenModal() {
    setModalOpen(missionSelected)
  }

  function minutesToAmount(minutes, hourlyRate) {
    const hours = minutes / 60;
    const amount = hours * hourlyRate;
    return amount.toFixed(2);
  }

  function handleGoToMap() {
    const url = `campagnes?campaign_id=${missionSelected?.campaign?.id}&maps=true`
    window.open(url, '_blank');
  }

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} Heures ${mins.toString().padStart(2, '0')} minutes`;
  };

  return (
    <div className="right-component-container">
      <div className="right-scrollable">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={"categorie-title"}>
            INFORMATIONS SUR LA MISSION {missionSelected?.is_backup && '(BACKUP)'}
            <ArrowUp
              onClick={() => navigate(`/admin/campagnes?campaign_id=${missionSelected?.campaign?.id}&prestation_id=${missionSelected?.prestation?.id}&slot_id=${missionSelected?.slot?.id}`)}
              className={"categorie-icon"}
              width={20}
              height={20}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}><BtnComponent text={getStatus(missionSelected.validated, missionSelected.treated)} variant="regular" color={"orange"} isIcon /></div>
        </div>
        <div className={"categorie-separate between"}>
          <div>
            <div onClick={() => navigate(`/admin/street-marketeurs?marketer_id=${missionSelected?.user?.id}`)} style={{ display: 'inline', cursor: 'pointer' }}>{missionSelected?.user?.first_name} {missionSelected?.user?.last_name?.toUpperCase()}</div>
            <div><div onClick={() => navigate(`/admin/campagnes?campaign_id=${missionSelected?.campaign?.id}`)} style={{ display: 'inline', cursor: 'pointer' }}>{missionSelected?.campaign?.campaign_name}</div> - {missionSelected?.prestation?.presta_type} - {moment(missionSelected?.check_in)?.format("DD/MM")}</div>
            <div>Taux horaire : {missionSelected?.slot?.cost}€/H</div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 5 }}>La mission à bien été effectuée ?</div>
            <GreenSwitch checked={newData?.validated || false} onChange={() => setNewData(prev => prev ? ({ ...prev, validated: !prev?.validated }) : ({ validated: true }))} />
          </div>
        </div>
        <div className="categorie-separate between">
          <div>
            <div className={`categorie-title`}>TEMPS PASSÉ SUR LE TERRAIN</div>
            <div style={{ display: "flex" }}>Heure de check-in : &nbsp; <div className="info-value">{missionSelected?.check_in ? moment(missionSelected?.check_in)?.format("HH:mm - DD/MM") : "Pas de check in"}</div></div>
            <div style={{ display: "flex" }}>Heure de check-out :  &nbsp; <div className="info-value">{missionSelected?.check_out ? moment(missionSelected?.check_out)?.format("HH:mm - DD/MM") : "Pas de check out"}</div></div>
            <div style={{ display: "flex" }}>Temps de pause :  &nbsp; <div className="info-value">{loading ? <CircularProgress /> : (missionSelected.pausedTime ? formatMinutes(missionSelected.pausedTime) : "Pas de pause")}</div></div>
            <div style={{ display: "flex" }}>
              <div style={{ marginBottom: 20 }}>Temps réel passé sur la mission : </div>
              {
                missionSelected?.real_time > 0
                ? <div>
                  <div style={{ margin: "0px 5px", color: newData?.real_time ? "red" : "black" }}>
                  {formatTime(missionSelected.real_time)}
                </div>
                </div>
                : <div style={{ margin: "0px 5px", color: newData?.real_time ? "red" : "black" }}>
                  {missionSelected?.check_out && missionSelected?.check_out ? formatMinutes(calculateDifferenceInMinutes(missionSelected.check_in, missionSelected.check_out) - missionSelected.pausedTime) : "Impossible de le calculer"}
                </div>
              }
              <div>{newData?.real_time && ` / ${formatMinutes(newData?.real_time)}`}</div>
            </div>
            <div style={{ display: "flex" }}>
              <BtnComponent text={"MODIFIER LES HEURES"} color={"white"} variant="rounded" onClick={handleOpenModal} />
            </div>
          </div>
          {missionSelected?.prestation?.presta_type === 'Flying Hand' && <SpotsNoteSection setMissionSelected={setMissionSelected} spots={missionSelected?.slot?.spots} />}
        </div>
        <div className="categorie-separate">
          <div className={`categorie-title`}>NOTE DU SM</div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: 15 }}>
            <div>Assigner une note de mission : </div>
            <Rating
              name="rating"
              value={newData?.rating || 0}
              onChange={(e, val) => {
                setNewData(prev => prev ? ({ ...prev, rating: val }) : ({ rating: val }))
              }}
              precision={0.5}
            />
          </div>
          <InputText value={newData?.commentary || ""} label="DESCRIPTION DE LA NOTE" variant="rounded" placeholder="Saisir les informations..." multiline onChange={e => setNewData(prev => prev ? ({ ...prev, commentary: e }) : ({ commentary: e }))} />
        </div>
        <div className="categorie-separate">
          <div className={`categorie-title`}>DOCUMENTS ET DÉPENSES</div>
          <div style={{ display: "flex", justifyContent: "space-between", gap: 30 }}>
            {missionSelected?.is_backup &&
              <InputText
                value={newData?.backup_cost || missionSelected?.slot?.backup_cost}
                label="MONTANT BONUS BACKUP"
                variant="rounded"
                type={"number"}
                onChange={(e) => setNewData((prev) => prev ? ({ ...prev, backup_cost: e }) : ({ backup_cost: e }))}
                disabled={false}
              />
            }
            <InputText value={newData?.bonus_sm || ""} label="MONTANT DU BONUS SM" variant="rounded" type={"number"} onChange={e => setNewData(prev => prev ? ({ ...prev, bonus_sm: e }) : ({ bonus_sm: e }))} />
            <SelectInputMultiple disabled label="AJOUTER UNE NOTE DE FRAIS" value={documentWaitingSend} items={documentWaitingSend} isBtn onRemove={handleRemoveDocument} onClick={handleAddDocument} variant={"rounded"} icon={<TrashSvg />} />
          </div>
        </div>
        <div className="categorie-separate">
          <div className={`categorie-title`}>CARTE DE MISSION</div>
          <div style={{ display: 'flex' }}>
            <BtnComponent text={"LIEN VERS LA CARTE"} color={"white"} variant="rounded" onClick={handleGoToMap} />
          </div>
        </div>
        <div className="categorie-separate">
          <div className={`categorie-title`}>RÉMUNÉRATION DU SM</div>
          <div>Montant perçu par le SM : {totalAmount} €</div>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center", margin: "20px 0" }}>
        <BtnComponent text="VALIDER LA MISSION" disabled={!newData?.validated} variant="rounded" onClick={() => handleSubmitData()} />
      </div>
    </div>)
}

export default RightBlockValidation;

/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState } from 'react'
import { ReactComponent as DropdownSvg } from 'assets/dropdown-icon.svg'
import SMPhoto from './SMPhoto'
import './style.scss'
import { BlackBtn } from "views/Admin/Shared/Forms/BlackBtn";
import API from "services/axios-config";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from 'react-router-dom'

const ServicePhoto = (props) => {
  const { prestationImages, addAdminPhotos } = props
  const [isServiceOpen, setIsServiceOpen] = useState(false)
  const fileInputRef = useRef(null);
  const [searchParams] = useSearchParams()
  const [isAdminPictureUploadLoading, setIsAdminPictureUploadLoading] = useState(false);
  const campaignId = Number(searchParams.get('campaign_id'))

  const toggleService = () => {
    setIsServiceOpen((prev) => !prev)
  }

  const prestationType = useMemo(() => {
    return prestationImages[0]
  }, [prestationImages[0]])

  const prestationImagesByMission = useMemo(() => {
    return prestationImages[1];
  }, [prestationImages[1]])

  const addNewAdminPhotos = (input) => {
    const headers = { "Content-Type": "multipart/form-data" };
    const formData = new FormData();

    Array.from(input.target.files).forEach((file, index) => {
      const prefix = "admin-picture-";
      const newFileName = `${prefix}${file.name}`;
      const renamedFile = new File([file], newFileName, { type: file.type });
      formData.append(`picture-${index}`, renamedFile);
    });

    formData.append("prestation_type", prestationType);

    setIsAdminPictureUploadLoading(true);
    API.post(`campaigns/${campaignId}/add_campaign_photos_by_prestation_type`, formData, {
      headers,
    }).then((res) => {
      addAdminPhotos(res.data, prestationType)
    })
      .catch((err) =>
        enqueueSnackbar(
          "Une erreur est survenue lors de l'envoi du document" + err
        )
      )
      .finally(() => setIsAdminPictureUploadLoading(false));
  };

  return (
    <div className="service-photo-container">
      <div className="service-photo-header" onClick={toggleService}>
        <DropdownSvg
          className={`dropdown-icon ${isServiceOpen ? 'open' : ''}`}
        />
        <div className="service-name">{prestationType}</div>
      </div>
      {isServiceOpen &&
        <>
          <BlackBtn
            label="Ajout Photo Admin"
            styles={{ textAlign: "center", padding: "8px", margin: "auto 10px" }}
            handleAction={() => fileInputRef.current.click()}
            disabled={isAdminPictureUploadLoading}
            center
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={addNewAdminPhotos}
            multiple
          />
          {Object.entries(prestationImagesByMission).map(
            (mission, index) => {
              return (
                <div key={`${prestationType}-mission-${index}`}>
                  <SMPhoto {...props} missionImages={mission} />
                </div>
              )
            })}
        </>}
    </div>
  )
}

export default ServicePhoto

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import InfosCandidate from '../InfosCandidate'
import InfosMission from '../InfosMission'
import './style.scss'

const MissionView = (props) => {
  const { currentSlot } = props
  const [currentMissions, setCurrentMissions] = useState([])
  const [currentCandidates, setCurrentCandidates] = useState([])

  useEffect(() => {
    setCurrentMissions(currentSlot?.missions?.filter(mission => !['Refusé', 'En attente de validation'].includes(mission?.status)))
    setCurrentCandidates(currentSlot?.missions?.filter(mission => ['Refusé', 'En attente de validation'].includes(mission?.status)))
  }, [currentSlot])
  return (
    <>
      {currentMissions?.length > 0 &&
        <div className='infos-candidate'>
          <div className='infos-candidate-title'>Mission{currentMissions?.length > 1 ? 's' : ''}:</div>
          <div>
            {
              currentMissions.map((mission, index) => (
                <InfosMission key={`mission-item-${index}`} {...props} {...{ mission }} />))
            }
          </div>
        </div>
      }
      {currentCandidates?.length > 0 &&
        <div className='infos-candidate'>
          <div className='infos-candidate-title'>Candidat{currentCandidates?.length > 1 ? 's' : ''}:</div>
          <div>
            {
              currentCandidates.map((candidate, index) => (
                <InfosCandidate key={`candidate-item-${index}`} {...props} candidate={candidate} />))
            }
          </div>
        </div>
      }
      </>
  )
}

export default MissionView

/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { ReactComponent as DropdownSvg } from "assets/dropdown-icon.svg";
import { ReactComponent as MapSvg } from "assets/map.svg";
import CheckIcon from "@mui/icons-material/Check";
import { Modal } from "@mui/material";
import DisplaySinglePhoto from "./DisplaySinglePhoto";
import "./style.scss";

const SMPhoto = (props) => {
  const { missionImages, select, selectedImages, setSelectedImages } = props;
  const [isSMPhotoOpen, setIsSMPhotoOpen] = useState(false);
  const [displayedImage, setDisplayedImage] = useState(null);

  const toggleSMPhoto = () => {
    setIsSMPhotoOpen((prev) => !prev);
  };

  const handleAddPhoto = (image) => {
    if (selectedImages.find((selected) => selected.id === image.id)) {
      setSelectedImages(
        selectedImages.filter((item) => item.id !== image.id)
      );
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  return (
    <div>
      <div className="photo-header" onClick={toggleSMPhoto}>
        <DropdownSvg
          className={`dropdown-icon ${isSMPhotoOpen ? "open" : ""}`}
        />
        <div className="photo-info">{missionImages[0]}</div>
      </div>
      {isSMPhotoOpen && (
          <div className="photo-list">
            {missionImages[1]?.map((image, index) => {
              const urlImage = image.picture_url;
              const checkInOutLabel = image.picture_url.includes("checkin")
                ? "check_in"
                : image.picture_url.includes("checkout")
                  ? "check_out"
                  : null;

            if (!urlImage) return;
            return (
              <div
                key={index}
                className="photo-item"
                onClick={() => setDisplayedImage(image)}
              >
                <div onClick={(e) => {
                  e.stopPropagation();
                  handleAddPhoto(image);
                }}>
                  {select &&
                    (selectedImages.some(
                      (selected) => selected.id === image.id
                    ) ? (
                      <div className="select-icon">
                        <CheckIcon className="check" />
                      </div>
                    ) : (
                      <div className="select-icon-box" />
                    ))}
                </div>
                {checkInOutLabel && <div className="photo-check-in-out-label">{checkInOutLabel}</div>}
                <img
                  src={process.env.REACT_APP_BACK_API + urlImage}
                  alt=""
                  className="photo-image"
                />
                {image.client_visible && <div className="published-picture-logo-container"><MapSvg style={{ width: 20, height: 20 }} /></div>}
              </div>
            );
          })}
        </div>
      )}
      <Modal open={!!displayedImage} onClose={() => setDisplayedImage(null)}>
        <DisplaySinglePhoto
          {...props}
          {...{ displayedImage, setDisplayedImage }}
          handleAddPhoto={handleAddPhoto}
          photoArray={missionImages[1]}
        />
      </Modal>
    </div>
  );
};

export default SMPhoto;

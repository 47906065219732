/* eslint-disable react/prop-types */
import { Box, Modal, Popper, Switch } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import React, { useEffect, useState } from 'react'
import { InputComponent } from 'views/Admin/Shared/Forms/Input'
import './style.scss'
import { enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'
// import { changeCampaignStatus } from '_redux/Map/reducers'
// import { useDispatch } from 'react-redux'
import moment from 'moment'
import { SelectAddressComponent } from 'components/SelectAddressComponent'

const DateInfos = ({ dateValue, setDateValue, slot }) => {
  return (
    <div>
      <div className="DateInfos_container">
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <DatePicker
            key={`first-picker-${slot?.id}`}
            value={dateValue?.dateStart ? moment(dateValue?.dateStart) : null}
            onChange={(e) => setDateValue((prev) => ({ ...prev, dateStart: new Date(e) }))}
            label="Jour de début"
            className="DateInfos_DateTimePicker"
          />
        </LocalizationProvider>
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <TimePicker
            key={`first-picker-${slot?.id}`}
            value={dateValue?.dateStart ? moment(dateValue?.dateStart) : null}
            onChange={(e) => setDateValue((prev) => ({ ...prev, dateStart: new Date(e) }))}
            label="Heure de début"
            className="DateInfos_DateTimePicker"
            popper={<Popper disablePortal/>}
          />
        </LocalizationProvider>
      </div>
      <div className="DateInfos_container">
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <DatePicker
            key={`second-picker-${slot?.id}`}
            value={dateValue?.dateEnd ? moment(dateValue?.dateEnd) : null}
            onChange={(e) => setDateValue((prev) => ({ ...prev, dateEnd: new Date(e) }))}
            label="Jour de fin"
            className="DateInfos_DateTimePicker"
          />
        </LocalizationProvider>
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <TimePicker
            key={`second-picker-${slot?.id}`}
            value={dateValue?.dateEnd ? moment(dateValue?.dateEnd) : null}
            onChange={(e) => setDateValue((prev) => ({ ...prev, dateEnd: new Date(e) }))}
            label="Heure de fin"
            className="DateInfos_DateTimePicker"
          />
        </LocalizationProvider>
      </div>
    </div>
  )
}

const DateModal = ({ open, setModalOpen, slot, handleSendModify, campaign }) => {
  const [dateValue, setDateValue] = useState({
    dateStart: slot?.date_start_mission,
    dateEnd: slot?.date_end_mission
  })
  const [actif, setActif] = useState(slot?.active)
  const [price, setPrice] = useState(slot?.cost)
  const [address, setAddress] = useState(slot?.address)
  const [isInResearch, setIsInResearch] = useState(false)
  const [modalOpen, setCurrentModalOpen] = useState(open)
  // const dispatch = useDispatch()

  useEffect(() => {
    setDateValue({
      dateStart: slot?.date_start_mission,
      dateEnd: slot?.date_end_mission
    })
    setActif(slot?.active)
    setPrice(slot?.cost)
    setAddress(slot?.address)
    setCurrentModalOpen(open)
  }, [open, slot])

  function handleSwitch (e) {
    if ((price && dateValue.dateEnd && dateValue.dateStart && address) || actif) {
      setActif(e.target.checked)
    } else {
      enqueueSnackbar('Merci de bien vouloir remplir tous les champs pour rendre le slot actif', { variant: 'error' })
    }
  }

  function handleEnregistrer (type) {
    switch (type) {
      case 'save':
        if (actif && (!price || !dateValue.dateEnd || !dateValue.dateStart || !address)) {
          enqueueSnackbar('Merci de bien vouloir remplir tous les champs pour rendre le slot actif', { variant: 'error' })
        } else if (actif && campaign?.status !== 'En recherche de SM') {
          setIsInResearch(true)
        } else {
          saveSlot()
        }
        break
      case 'researchSM':
        API.patch(`/campaigns/${campaign?.id}`, { status: 'En recherche de SM' })
          .then(_ => {
            enqueueSnackbar('Statut de campagne passé ', { variant: 'success' })
            saveSlot()
            setIsInResearch(false)
            // dispatch(changeCampaignStatus('En recherche de SM'))
          })
          .catch(_ => enqueueSnackbar("Le statut de la campagne n'a pas été modifié", { variant: 'error' }))
        break
      case 'stay':
        saveSlot()
        setIsInResearch(false)
        break
    }

    function saveSlot () {
      setModalOpen(null)
      handleSendModify({
        active: actif,
        cost: price,
        address,
        date_start_mission: dateValue.dateStart,
        date_end_mission: dateValue.dateEnd
      })
    }
  }

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(null)
        setIsInResearch(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="DateModal_container"
    >
      <Box sx={style}>
        <div className="DateModal_Box_container">
          <div className="DateModal_Box_header">
            <div className="DateModal_Box_header-title">
              Informations générales du slot
            </div>
            <div className="DateModal_Box_header-switch">
              <div style={{ fontSize: 10 }}>Actif</div>
              <Switch
                checked={!!actif}
                onChange={(e) => handleSwitch(e)}
                size="small"
              />
            </div>
          </div>
          <div className="DateModal_Box_tarif-horaire">
            <InputComponent
              label="Tarif horaire"
              type="number"
              defaultValue={slot?.cost}
              handleAction={(e) => setPrice(e)}
            />
            {!price && <span style={{ color: 'red', fontSize: '12px', padding: '10px 0 0 10px' }}>Sans Tarif, ce créneau ne sera pas affiché sur l&apos;application.</span>}
          </div>
          <div className="DateModal_Box_tarif-date">
            <DateInfos {...{ dateValue, setDateValue, slot }} />
            {(!dateValue?.dateStart || !dateValue?.dateEnd) && <span style={{ color: 'red', fontSize: '12px', paddingLeft: '10px' }}>Sans date, ce créneau ne sera pas affiché sur l&apos;application.</span>}
          </div>
          <SelectAddressComponent
            label={'ADDRESSE'}
            onSelectAddress={(value) => setAddress(value)}
            address={address?.label}
          />
          {!address?.label && <span style={{ color: 'red', fontSize: '12px', paddingLeft: '10px' }}>Sans adresse, ce créneau ne sera pas affiché sur l&apos;application.</span>}
          {isInResearch
            ? <div className="DateModal_Box_SMstate">
              <div>
                Votre slot est actif mais la campagne n&apos;est pas en recherche de SM, voulez-vous la passer en recherche de SM ?
              </div>
              <div className="DateModal_Box_SMstate_button-container">
                <div className="DateModal_Box_SMstate_button" onClick={() => handleEnregistrer('researchSM')}>Passez en recherche de SM</div>
                <div className="DateModal_Box_SMstate_button" onClick={() => handleEnregistrer('stay')}>Rester dans l&apos;état actuel</div>
              </div>
            </div>
            : <div
              className="DateModal_Box_save-btn"
              onClick={() => handleEnregistrer('save')}
            >
              Enregistrer
            </div>
          }
        </div>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  width: window.innerWidth < 900 ? '80%' : undefined
}

export default DateModal

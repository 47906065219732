import { memo, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addActionToStack, addItemToDrag, resetItemsDrag } from '_redux/Map/reducers'
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'
import API from 'services/axios-config'
import ConfirmationModal from '../confirmationModal'
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import './style.scss'
import { DrawMarker } from '../../DrawingComponents/Marker'
import { ItemTitleIcon } from '../components/ItemsTitleIcon'
import { InputChangeName } from '../components/ItemChangeTitle'
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ReactComponent as MyMapVision } from 'assets/map-vision.svg'
import { ReactComponent as ForgottenSvg } from 'assets/forgotten.svg'
import { getItemType } from '../DraggableList/function'
import { anomalieSvg } from "utils/helpers";
import { handleGetDetails } from 'components/SelectAddressComponent/functions'
import { enqueueSnackbar } from 'notistack'

const SpotView = memo(({ currentSpot, clientView }) => {
  const dispatch = useDispatch()
  const { multiSelectType, handleOpenMarkerInfos, removeItems, handleZoomMap, modifyItems, handleClientVisibility, clientVisibility, mapItemsVisibility, updateSingleItemVisibilityByType, setAnomaliesParamsListForMarkerCluster } = useCampaignMap()
  const itemsDragSelected = useSelector(state => state.map.itemsDragSelected)
  const [isHover, setIsHover] = useState(false)
  const isMoving = useSelector(state => state.map.isMoving)

  const handleClickLeftLayoutItemIcon = (type) => {
    if (type === "spot") {
      const spotLatLng = {
        lat: currentSpot?.address?.latitude,
        lng: currentSpot?.address?.longitude
      }
      handleOpenMarkerInfos(spotLatLng, currentSpot)
    }
  }

  function handleAddItemDrag () {
    dispatch(addItemToDrag({ type: multiSelectType, value: { type: 'spot', ...currentSpot } }))
    if (multiSelectType === 'default') {
      handleZoomMap(currentSpot)
    }
  }
  const [openValidation, setOpenValidation] = useState(false)
  const [modifyName, setModifyName] = useState(false)

  const isSelected = itemsDragSelected.filter(current => current.type === "spot" && current.id === currentSpot?.id).length > 0
  const isMultipleSelect = itemsDragSelected.length > 1

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setIsVisible(mapItemsVisibility?.spots?.[currentSpot.id] ?? true);
  }, [mapItemsVisibility?.spots?.[currentSpot.id], isVisible])

  const updateIsVisible = (value) => {
    updateSingleItemVisibilityByType(currentSpot.id, 'spots', value);
  };

  function handleClickItem (latLng) {
    if (multiSelectType === 'cmd') {
      setIsHover(false)
      handleAddItemDrag()
    } else {
      handleOpenMarkerInfos(latLng, currentSpot)
    }
  }

  const isAnoamlie = useMemo(() => {
    return !!currentSpot?.anomalie_type;
  }, [currentSpot])

  const params = useMemo(() => {
    const latLng = { lat: currentSpot.address.latitude, lng: currentSpot.address.longitude }
    return ({
      id: currentSpot.id,
      position: latLng,
      center: latLng,
      radius: currentSpot.geofence_radius || 0,
      onClick: () => handleClickItem(latLng),
      options: {
        fillColor: currentSpot.fillColor,
        strokeColor: currentSpot.fillColor,
      },
      icon: isAnoamlie
      ? {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(anomalieSvg)}`,
        scaledSize: new window.google.maps.Size(40, 40),
      }
      : {
        path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
        scale: 6,
        fillColor: currentSpot.fillColor,
        strokeColor: currentSpot.fillColor,
        fillOpacity: 1,
        strokeWeight: 1
      }
    })
  }, [currentSpot, isAnoamlie])

  useEffect(() => {
    if (isAnoamlie) {
      setAnomaliesParamsListForMarkerCluster(prev => {
        const index = prev.findIndex(item => item.id === params.id);
        if (index !== -1) {
          return prev.map((item, idx) =>
            idx === index ? params : item
          );
        } else {
          return [...prev, params];
        }
      });
    }
  }, [params, isAnoamlie])

  function handleZoneIsHover (value) { setIsHover(value) }

  function handleRemoveSpot (e) {
    e.stopPropagation()
    setOpenValidation(true)
  }

  function validAction (e) {
    e.stopPropagation()
    if (currentSpot.anomalie_type) {
      API.patch(`spots/${currentSpot.id}`, { admin_visible: false, client_visible: false });
      updateSingleItemVisibilityByType(currentSpot.id, 'spots', false);
    } else {
      API.delete(`spots/${currentSpot.id}`)
      dispatch(addActionToStack({ type: "delete", itemType: "spot", item: currentSpot }))
      if (!isMultipleSelect) { dispatch(resetItemsDrag()) }
    }
    removeItems(currentSpot)
  }

  function handleSendNewname (value) {
    API.patch(`spots/${currentSpot.id}`, { title: value })
    modifyItems({ ...currentSpot, title: value })
    setModifyName(false)
  }

  const isItClientVisible = clientVisibility?.items?.find((zone) => (zone?.id === currentSpot.id && getItemType(zone) === getItemType(currentSpot)))

  const handleUpdateNewLocation = (value) => {
    API.patch(`spots/${currentSpot.id}`, { address_attributes: value }).then(() => {
      modifyItems({ ...currentSpot, address: value });
    }).catch((err) => enqueueSnackbar(`Une erreur est survenu lors de mis a jour d'address : ${err}`, { variant: 'error' }))
  }
  const handleDragMarker = (e) => {
    const { latLng } = e;
    const newPosition = {
      longitude: e.latLng.lng(),
      latitude: e.latLng.lat()
    };

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
        handleGetDetails(results[0], (formattedAddress) => {
          const newLabel = [
            formattedAddress.housenumber ? formattedAddress.housenumber : null,
            formattedAddress.street ? `${formattedAddress.street},` : null,
            formattedAddress.postcode ? formattedAddress.postcode : null,
            formattedAddress.city ? formattedAddress.city : null
          ].filter(Boolean).join(' ');
          const finalAddress = {
            ...formattedAddress,
            ...newPosition,
            label: newLabel,
          }
          handleUpdateNewLocation(finalAddress)
        })
      } else {
        const finalAddress = {
          ...newPosition,
          label: null,
          zip_code: null,
          name: null,
          street: null,
        }
        handleUpdateNewLocation(finalAddress)
      }
    });
  }

  const handleToggleSpotForgotten = () => {
    API.patch(`spots/${currentSpot.id}`, { forgotten: !currentSpot.forgotten })
      .then((res) => {
        modifyItems({ ...currentSpot, forgotten: res.data.spot.forgotten })
      })
  }

  return (
    <div
      onClick={handleAddItemDrag}
      onMouseOver={() => handleZoneIsHover(true)}
      onMouseLeave={() => handleZoneIsHover(false)}
      className={`spot-view-container ${currentSpot?.forgotten && 'forgotten'} ${(isSelected) && 'selected'} ${isSelected && isMultipleSelect && 'multiple'} ${isSelected && isMoving && 'move'} ${(isHover) && 'isHover'} ${!isVisible && 'hidden'}`}
    >
      <div style={{ display: "flex", height: "100%", overflow: "hidden" }}>
        <ItemTitleIcon type={'spot'} item={currentSpot} onIconClick={() => handleClickLeftLayoutItemIcon("spot")}/>
        {!modifyName
          ? <div className={`spot-name ${isHover && "isHover"}`} onClick={() => isSelected && setModifyName(true)}>
            {currentSpot?.title || (currentSpot.address?.label ?? "[ECRIRE UN NOM]")}
          </div>
          : <InputChangeName text={currentSpot?.title} handleChange={handleSendNewname}/>
        }
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {!clientView && <>
          {isHover && !isAnoamlie && <div onClick={handleToggleSpotForgotten} className="btn">
            <ForgottenSvg
              style={{ width: "18px" }}
              color={currentSpot.forgotten ? "#60EED2" : "#23272A"}
            />
          </div>}
          {((isHover || !isVisible)) && (
            <div onClick={(e) => {
              e.stopPropagation()
              updateIsVisible(!isVisible)
            }} className="btn">
              {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon /> }
            </div>
          )}
          {(((isHover || isItClientVisible) && (isVisible && !isItClientVisible)) || (currentSpot.client_visible || isItClientVisible)) &&
          <div onClick={(e) => handleClientVisibility(e, currentSpot)} className="btn">
            <MyMapVision
              style={{ width: "18px" }}
              color={
                (isItClientVisible && "#FF7A00") ||
                (currentSpot.client_visible && "#60EED2")
              }
            />
          </div>}
          {(((isHover && !isSelected) || (isSelected && !isMultipleSelect)) && isVisible) && (
            <div onClick={handleRemoveSpot} className="btn">
              <DeleteRoundedIcon color="error" />
            </div>
          )}
        </>}
      </div>
      {isVisible && !isAnoamlie && <DrawMarker {...{ setIsHover, params, handleDragMarker, isHover }}/>}
      <ConfirmationModal {...{ openValidation, setOpenValidation, validAction }}/>
    </div>
  )
})

export default SpotView
